import $ from 'jquery';

function toggleAllNavs() {
	$('html').removeClass('mega-nav-shown');
	$('.header-wrap li a.active, .scrolled-header-wrap li a.active').removeClass('active');
	$('.mega-nav.active').removeClass('active');
}

function tbxHandleMegaNavs() {
	$('body').on('click', function(e) {
		toggleAllNavs();
	});

	$('.mega-nav').on('click', function(e) {
		e.stopPropagation();
	});

	$('.header-wrap .toggle-academic-programs-meganav a').on('click', function(e) {
		e.preventDefault();
		e.stopPropagation();

		if ( $(this).hasClass('active') ) {
			toggleAllNavs();
		}
		else {
			toggleAllNavs();

			$('html').addClass('mega-nav-shown');
			$(this).addClass('active');
			$('.mega-nav.academic-programs').addClass('active');
		}
	});

	$('.header-wrap .toggle-admissions-meganav a').on('click', function(e) {
		e.preventDefault();
		e.stopPropagation();

		if ( $(this).hasClass('active') ) {
			toggleAllNavs();
		}
		else {
			toggleAllNavs();

			$('html').addClass('mega-nav-shown');
			$(this).addClass('active');
			$('.mega-nav.admissions').addClass('active');
		}
	});

	$('.header-wrap .toggle-tuition-meganav a').on('click', function(e) {
		e.preventDefault();
		e.stopPropagation();

		if ( $(this).hasClass('active') ) {
			toggleAllNavs();
		}
		else {
			toggleAllNavs();

			$('html').addClass('mega-nav-shown');
			$(this).addClass('active');
			$('.mega-nav.tuition').addClass('active');
		}
	});

	$('.header-wrap .toggle-experience-meganav a').on('click', function(e) {
		e.preventDefault();
		e.stopPropagation();

		if ( $(this).hasClass('active') ) {
			toggleAllNavs();
		}
		else {
			toggleAllNavs();

			$('html').addClass('mega-nav-shown');
			$(this).addClass('active');
			$('.mega-nav.student-experience').addClass('active');
		}
	});

	// handle mega nav link clicks
	$('.mega-nav a').on('click', function() {
		toggleAllNavs();
	});
}

export default tbxHandleMegaNavs;